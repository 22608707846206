exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-about-js": () => import("./../../../src/pages/about/about.js" /* webpackChunkName: "component---src-pages-about-about-js" */),
  "component---src-pages-about-hjaerterum-js": () => import("./../../../src/pages/about/hjaerterum.js" /* webpackChunkName: "component---src-pages-about-hjaerterum-js" */),
  "component---src-pages-about-stoedoss-js": () => import("./../../../src/pages/about/stoedoss.js" /* webpackChunkName: "component---src-pages-about-stoedoss-js" */),
  "component---src-pages-article-index-js": () => import("./../../../src/pages/article/index.js" /* webpackChunkName: "component---src-pages-article-index-js" */),
  "component---src-pages-index-2-js": () => import("./../../../src/pages/index2.js" /* webpackChunkName: "component---src-pages-index-2-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */)
}

